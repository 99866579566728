<template>
	<div class="groupManage">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">接收时间:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">发送者:</div>
					<div class="item-input">
						<el-input v-model="searchForm.from" placeholder="请输入发送者昵称" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click.stop="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
				<span style="color:#F56C6C;padding-left:20px">仅保留最近15天的数据</span>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading" row-style="max-height:300px"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.replyList" :ref="'childTable' + props.row.id"
							@selection-change="handleChildSelectionChange($event, props.row)" :header-cell-style="{
								background: '#f5f7fa',
								fontWeight: 'bold',
								color: '#303133'
							}" style="width: 100%;border:1px solid #e6a23c">
							<el-table-column prop="created_at" label="回复时间" align="center"
								width="200"></el-table-column>
							<el-table-column prop="goods_name" label="回复类型" align="center" width="160">
								<template slot-scope="scope">
									<span v-if="scope.row.type == 1">文本</span>
									<span v-if="scope.row.type == 2">图片</span>
									<span v-if="scope.row.type == 3">视频</span>
									<span v-if="scope.row.type == 4">音频</span>
								</template>
							</el-table-column>
							<el-table-column label="回复内容" align="center">
								<template slot-scope="scope">
									<div v-if="scope.row.type == 1" v-html="scope.row.content"></div>
									<el-image style="width: 100px; height: 100px" :src="scope.row.content"
										:preview-src-list="[scope.row.content]" v-if="scope.row.type == 2">
									</el-image>
									<video style="width: 160px; height: 90px" controls :src="scope.row.content"
										v-if="scope.row.type == 3"></video>
									<audio style="width: 160px; height: 50px" controls :src="scope.row.content"
										v-if="scope.row.type == 4"></audio>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column label="接收时间" prop="created_at" align="center" width="160"></el-table-column>
				<el-table-column label="发送者类型" align="center" width="160">
					<template slot-scope="scope">
						<span v-if="scope.row.from_type == 'user'" style="color:#E6A23C">好友</span>
						<span v-if="scope.row.from_type == 'group'">群组</span>
					</template>
				</el-table-column>
				<el-table-column label="发送者昵称" prop="from" align="center" width="260"></el-table-column>
				<el-table-column label="内容类型" align="center" width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">文本</span>
						<span v-if="scope.row.type == 2">图片</span>
						<span v-if="scope.row.type == 3">视频</span>
						<span v-if="scope.row.type == 4">音频</span>
					</template>
				</el-table-column>
				<el-table-column label="接收内容" prop="content" align="left">
					<template slot-scope="scope">
						<div v-if="scope.row.type == 1" v-html="scope.row.content"></div>
						<el-image style="width: 100px; height: 100px" :src="scope.row.content"
							:preview-src-list="[scope.row.content]" v-if="scope.row.type == 2">
						</el-image>
						<video style="width: 160px; height: 90px" controls :src="scope.row.content"
							v-if="scope.row.type == 3"></video>
						<audio style="width: 160px; height: 50px" controls :src="scope.row.content"
							v-if="scope.row.type == 4"></audio>
					</template>
				</el-table-column>
				<el-table-column label="状态" align="center" width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.replyList" style="color:green">已回复</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click.stop="dialogVisible = true, dialogType = 2, openDialog(scope.row.id)">回复</el-button>
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog title="回复" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="form" :model="form" label-width="80px">

				<el-form-item label="内容类型" required>
					<el-radio-group v-model="form.type" @input="changeType">
						<el-radio label="1">文字</el-radio>
						<el-radio label="2">图片</el-radio>
						<el-radio label="3">视频</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="回复内容" v-if="form.type == 1">
					<el-input type="textarea" v-model="form.content" placeholder="请输入回复内容" maxlength="200" rows="5"
						show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="回复图片" v-if="form.type == 2">
					<el-upload class="avatar-uploader" :action="serverUrl" v-loading="uploading"
						:on-progress="handleAvatarProcess" :headers="uploadHeaders" :data="uploadParam"
						:show-file-list="false" :on-success="handleAvatarSuccess">
						<img v-if="form.pic" :src="form.pic" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="回复视频" v-if="form.type == 3">
					<el-upload class="avatar-uploader" :action="serverUrl" v-loading="uploading"
						:on-progress="handleAvatarProcess" :headers="uploadHeaders" :data="uploadParam"
						:show-file-list="false" :on-success="handleAvatarSuccess">
						<video controls v-if="form.video" :src="form.video" class="avatar"></video>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">回 复</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import CryptoJS from 'crypto-js'
	var timestamp = new Date().getTime()
	export default {
		data() {
			return {
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
				dateVal: [],
				searchForm: {},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 新增、编辑弹窗
				typeList: [{
						id: 1,
						name: '文本'
					},
					{
						id: 2,
						name: '图片'
					},
					{
						id: 3,
						name: '视频'
					}
				],
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					type: "1"
				},
				//上传相关
				uploadParam: {

				},
				uploadHeaders: {
					token: window.sessionStorage.getItem('token'),
					sign: CryptoJS.MD5(sessionStorage.getItem('token') + 'w2MDYDE8nx4CQdA8fn2Nmnexn4bSyBbh' + timestamp)
						.toString(),
					timestamp: timestamp
				},
				serverUrl: 'erp/v1/common/upload',
				uploading: false,
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']

			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/wx_message/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 编辑弹窗
			openDialog(id) {
				this.form.id = id
			},
			changeType(val) {
				if (val < 3) {
					this.$set(this.uploadParam, "type", 1)
					this.uploadParam.type == "1"
				} else {
					this.$set(this.uploadParam, "type", 3)
					this.uploadParam.type == "3"
				}
			},
			// 确定
			confirmFn() {
				if (this.form.type == 2) {
					this.form.content = this.form.pic
				}
				if (this.form.type == 3) {
					this.form.content = this.form.video
				}
				if (!this.form.content) {
					this.$message.error('发送内容不能为空');
					return
				}

				let url = '/erp/v1/wx_message/reply'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						//初始化参数
						this.form = {
							type: "1"
						}
						this.$set(this.uploadParam, "type", 1)
						//初始化参数结束
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/wx_message/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			handleAvatarSuccess(res, file) {
				this.uploading = false
				if (this.form.type == 2) {
					this.form.pic = res.data.path
				} else {
					this.form.video = res.data.path
				}

				this.$forceUpdate();
			},
			handleAvatarProcess(res, file) {
				this.uploading = true
			},
		},
	}
</script>

<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #FF720E;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #FF720E;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 125PX;
		height: 125PX;
		line-height: 125PX;
		text-align: center;
	}

	.avatar {
		width: 125PX;
		height: 125PX;
		display: block;
	}
</style>